<template>
  <div class="flex flex-col bg-offwhite rounded-xl shadow p-2 mobile:mb-3">
    <div
      class="flex flex-col justify-center items-center mb-2 font-medium rounded-xl"
      :class="
        hasDayView && screenW > 992
          ? 'hover:bg-offerwhite hover:shadow cursor-pointer'
          : ''
      "
      @click="hasDayView && screenW > 992 ? openDayView(day.date) : ''"
    >
      <span>{{ moment(day.date).format("DD.MM") }}</span>
      <span class="disabled font-normal">{{
        moment(day.date).format("dddd")
      }}</span>
      <span class="heading-underline"></span>
    </div>
    <div class="flex flex-col" :class="canAddTasks ? 'h-full' : ''">
      <task
        v-for="task in day.tasks"
        :key="task.id"
        :task="task"
        :company="company"
      ></task>
      <div
        class="flex cursor-pointer rounded-full items-center mt-auto"
        v-if="canAddTasks"
        @mouseover="day.hovered = true"
        @mouseleave="day.hovered = false"
        @click="openAddTaskView(day.date)"
      >
        <div
          class="flex justify-center items-center p-1 bg-transparent border-2 border-green rounded-full leading-none mr-2"
        >
          <span class="typcn typcn-plus text-green"></span>
        </div>
        <transition name="fade"
          ><span v-if="day.hovered" class="text-green font-semibold"
            >Lisa ülesanne</span
          ></transition
        >
      </div>
    </div>
  </div>
</template>

<script>
import Task from "./Task.vue";
import moment from "moment";
export default {
  props: {
    day: {
      type: Object,
      default: () => {
        return {};
      }
    },
    hasDayView: Boolean,
    company: {
      type: String,
      default: ""
    }
  },
  components: {
    Task
  },
  data() {
    return {
      moment: moment
    };
  },
  methods: {
    openAddTaskView(date) {
      this.$emit("openAddTaskView", date);
    },
    openDayView(date) {
      this.$emit("openDailyView", date);
    }
  },
  computed: {
    canAddTasks() {
      if (
        !this.$store.state.companyData.isClient &&
        ["R0", "R1", "R2", "R3"].includes(
          this.$store.state.companyData.activeCompany.worker_permission
        ) &&
        moment(this.day.date)
          .hour(23)
          .minute(59)
          .second(59)
          .isSameOrAfter(moment())
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
