<template>
  <div
    v-if="colorsLoaded"
    class="flex duration-100 rounded-xl shadow cursor-pointer break-words task"
    @click="viewTask"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      class="project-color"
      :class="colors.find(x => x.status === task.status).color"
    ></div>
    <div
      class="info"
      :class="[
        [5, 6].includes(task.status)
          ? colors.find(x => x.status === task.status).backgroundColor
          : colors.find(x => x.status === task.status).color,
        isHovered ? 'bg-opacity-75 text-white' : 'bg-opacity-5'
      ]"
    >
      <h4>{{ titleFormatted }}</h4>
      <div class="flex flex-row items-center justify-between">
        <span class="text-sm">{{
          task.all_day
            ? "Kogu päev"
            : `${startTimeFormatted} - ${endTimeFormatted}`
        }}</span>
      </div>
      <div
        class="flex items-center"
        v-if="task.related_object && task.related_object.client"
      >
        <img
          src="/bc21/project.svg"
          class="h-4 w-4 mr-2"
          :class="isHovered ? 'filter-to-white' : ''"
        />
        <h4 class="truncate">
          {{ task.related_object.object_name }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StatusParser from "@/assets/mixins/StatusParser";

const backgroundColours = [
  {
    status: 1,
    color: "bg-lightgrey"
  },
  {
    status: 2,
    color: "bg-pending"
  },
  {
    status: 3,
    color: "bg-attention"
  },
  {
    status: 4,
    color: "bg-danger"
  },
  {
    status: 5,
    color: "bg-turquoise",
    backgroundColor: "bg-turquoise"
  },
  {
    status: 6,
    color: "bg-darkgreen",
    backgroundColor: "bg-darkgrey"
  }
];

export default {
  props: {
    task: {
      type: Object,
      default: () => {
        return {};
      }
    },
    color: {
      type: String,
      default: ""
    },
    company: {
      type: String,
      default: ""
    }
  },
  mixins: [StatusParser],
  methods: {
    viewTask() {
      this.$store.dispatch("taskViewer/openTaskModal", {
        task: this.task.id,
        company: this.$store.state.companyData.isClient
          ? this.company
          : this.$store.state.companyData.activeCompany.uuid
      });
    }
  },
  data() {
    return {
      colors: [],
      colorsLoaded: false,
      isHovered: false
    };
  },
  mounted() {
    this.colors = backgroundColours;
    this.colorsLoaded = true;
  },
  computed: {
    startTimeFormatted() {
      return moment(this.task.start_time).format("HH:mm");
    },
    endTimeFormatted() {
      return moment(this.task.end_time).format("HH:mm");
    },
    titleFormatted() {
      if (window.innerWidth < 1100) {
        if (this.task.title.length > 8) {
          const wordArr = this.task.title.split(" ");
          if (wordArr.length > 8) {
            let strToReturn = "";
            for (let i = 0; i < wordArr.length; i++) {
              if (strToReturn.length > 8) {
                strToReturn += "...";
                return strToReturn;
              } else {
                if (wordArr[i].length < 8) {
                  strToReturn += wordArr[i] + " ";
                } else {
                  strToReturn += wordArr[i].slice(0, 8) + "...";
                }
              }
            }
            return strToReturn;
          } else {
            return this.task.title.slice(0, 8) + "...";
          }
        } else {
          return this.task.title;
        }
      } else if (window.innerWidth < 1460) {
        if (this.task.title.length > 10) {
          const wordArr = this.task.title.split(" ");
          if (wordArr.length > 10) {
            let strToReturn = "";
            for (let i = 0; i < wordArr.length; i++) {
              if (strToReturn.length > 10) {
                strToReturn += "...";
                return strToReturn;
              } else {
                if (wordArr[i].length < 10) {
                  strToReturn += wordArr[i] + " ";
                } else {
                  strToReturn += wordArr[i].slice(0, 10) + "...";
                }
              }
            }
            return strToReturn;
          } else {
            return this.task.title.slice(0, 10) + "...";
          }
        } else {
          return this.task.title;
        }
      } else {
        if (this.task.title.length > 12) {
          const wordArr = this.task.title.split(" ");
          if (wordArr.length > 12) {
            let strToReturn = "";
            for (let i = 0; i < wordArr.length; i++) {
              if (strToReturn.length > 12) {
                strToReturn += "...";
                return strToReturn;
              } else {
                if (wordArr[i].length < 12) {
                  strToReturn += wordArr[i] + " ";
                } else {
                  strToReturn += wordArr[i].slice(0, 12) + "...";
                }
              }
            }
            return strToReturn;
          } else {
            return this.task.title.slice(0, 12) + "...";
          }
        } else {
          return this.task.title;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.task {
  @apply mb-2;
  .info {
    @apply flex flex-col flex-auto p-2 rounded-r-xl max-w-full;
  }
}
</style>
